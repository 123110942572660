/* eslint-disable no-use-before-define */
import React, { useEffect, useReducer } from "react";
import { graphql } from "gatsby";
import { get, camelCase, upperFirst } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Button, Select } from "antd";

import { ContentBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { removeEmptyKeys, removePreloader } from "../../helpers/index";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import Masonry from "../../components/Masonry";
import seoData from "../../components/SEO/data";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

import {
	ALL_NEWS,
	ALL_FILTERED_NEWS,
	ALL_NEWS_MAX_UPDATE,
} from "../../queries/queries.graphql";

export const query = graphql`
	query allNewsQuery($category: [String!], $pagePath: String!) {
		hasura {
			...AllNews
			...Categories
			...Cities
			...PageSettings
		}
	}
`;

export default function AllNewsPage({ data, pageContext }) {
	const { Option } = Select;
	const limit = 10;
	const filteredLimit = 10;
	const headers = {
		fetchPolicy: "cache-and-network",
		partialRefetch: true,
	};

	function formatData(data) {
		return data.map(item => (
			{
				type: upperFirst(camelCase(item.type)),
				data: item,
			}
		));
	}

	const { theme } = useThemeContext();

	function init() {
		return {
			news: formatData(get(data, "hasura.v_news", [])),
			offset: limit,
			filteredOffset: 0,
			category: get(pageContext, "category", []).length === 1 ? get(pageContext, "category[0]", "") : undefined,
			city: undefined,
			seo: get(data, "hasura.page_settings[0]", {}),
		};
	}

	function reducer(state, { type, payload }) {
		switch (type) {
			case "category":
				return {
					...state,
					category: payload,
					filteredOffset: state.category !== payload ? 0 : state.filteredOffset,
				};
			case "city":
				return {
					...state,
					city: payload,
					filteredOffset: state.city !== payload ? 0 : state.filteredOffset,
				};
			case "offset":
				return {
					...state,
					offset: payload,
				};
			case "filteredOffset":
				return {
					...state,
					filteredOffset: payload,
				};
			case "news":
				return {
					...state,
					news: payload,
				};
			case "seo":
				return {
					...state,
					seo: payload,
				};
			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer(reducer, {}, init);

	const { news, offset, filteredOffset, category, city, seo } = state;

	function makeOptions(items) {
		return items.map((item, idx) =>
			<Option
				value={get(item, "slug", "")}
				key={`category-${idx}`}
			>
				{get(item, "title_full", "")}
			</Option>,
		);
	}

	const [
		loadNews,
		{
			called: allNewsCalled,
			loading: allNewsLoading,
			error: allNewsError,
			data: newsData,
		},
	] = useLazyQuery(
		ALL_NEWS,
		{
			variables: {
				category,
				limit,
			},
			...headers,
		});

	const [
		loadDynamicNews,
		{
			called: allNewsDynamicCalled,
			loading: allNewsDynamicLoading,
			error: allNewsDynamicError,
			data: newsDynamicData,
		},
	] = useLazyQuery(
		ALL_NEWS,
		{
			variables: {
				category,
				limit,
			},
			...headers,
		});

	const [
		loadFilteredNews,
		{
			called: filterdCalled,
			loading: loadingFilteredNews,
			error: filteredError,
			data: filteredNewsData,
		},
	] = useLazyQuery(ALL_FILTERED_NEWS, headers);

	const maxUpdated = new Date(get(data, "hasura.v_news.aggregate.max.updated_at", new Date()));

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(ALL_NEWS_MAX_UPDATE, headers);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_all_news_aggregate.aggregate.max.updated_at", new Date()));
		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadDynamicNews();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load NewsMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (allNewsDynamicCalled && !allNewsDynamicError && !allNewsDynamicLoading) {
			dispatch(
				{
					type: "news",
					payload: [...formatData(get(newsDynamicData, "v_all_news", []))],
				},
			);
			dispatch(
				{
					type: "offset",
					payload: limit,
				},
			);
		} else if (allNewsDynamicError) {
			dispatch(
				{
					type: "offset",
					payload: null,
				},
			);
		}

		removePreloader(!allNewsDynamicLoading || allNewsDynamicError);
	}, [allNewsDynamicLoading]);

	useEffect(() => {
		if (allNewsCalled && !allNewsError && !allNewsLoading) {
			dispatch(
				{
					type: "news",
					payload: [...news, ...formatData(get(newsData, "v_all_news", []))],
				},
			);
			dispatch(
				{
					type: "offset",
					payload: get(newsData, "v_all_news", []).length < limit ? null : offset + limit,
				},
			);
		} else if (allNewsError) {
			dispatch(
				{
					type: "offset",
					payload: null,
				},
			);
		}

		removePreloader(!allNewsLoading || allNewsError);
	}, [allNewsLoading]);

	useEffect(() => {
		if (filterdCalled && !filteredError && !loadingFilteredNews) {
			const data = get(filteredNewsData, "v_all_news", []);

			dispatch(
				{
					type: "news",
					payload: !filteredOffset ? formatData(data) : [...news, ...formatData(data)],
				},
			);
			dispatch(
				{
					type: "filteredOffset",
					payload: data.length < filteredLimit ? null : filteredOffset + filteredLimit,
				},
			);
		}
	}, [loadingFilteredNews]);

	useEffect(() => {
		const variables = {
			category,
			city,
			offset: filteredOffset,
			limit: filteredLimit,
		};

		if (!category) {
			delete variables.category;
		}

		if (!city) {
			delete variables.city;
		}

		if (!!category || !!city) {
			loadFilteredNews({
				variables,
			});
		}

		if (!category && !city) {
			dispatch(
				{
					type: "news",
					payload: formatData(get(data, "hasura.v_news", [])),
				},
			);
			dispatch(
				{
					type: "offset",
					payload: limit,
				},
			);
			dispatch(
				{
					type: "filteredOffset",
					payload: 0,
				},
			);
		}
	}, [category, city]);

	function loadMore() {
		const { category, city } = state;

		if (!!category || !!city) {
			const variables = {
				category,
				city,
				offset: filteredOffset,
				limit: filteredLimit,
			};

			if (!category) {
				delete variables.category;
			}

			if (!city) {
				delete variables.city;
			}
			loadFilteredNews({
				variables,
			});
		}

		if (!category && !city) {
			loadNews({
				variables: {
					offset,
				},
			});
		}
	}

	const url = "news";
	const categoryOptions = makeOptions(get(data, "hasura.categories", []));
	const cityOptions = makeOptions(get(data, "hasura.cities", []));

	return (
		<Pages url={url} entity={seo || get(seoData, "all_news", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Новости"} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container d-xs-block d-sm-block d-md-flex d-lg-flex justify-content-between"}>
					<h1 itemProp="headline"
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						Все новости
					</h1>
					<div>
						<Select
							placeholder={"Рубрики"}
							showArrow={false}
							style={{ minWidth: 120 }}
							onChange={category =>
								dispatch(
									{
										type: "category",
										payload: category,
									},
								)
							}
							dropdownMatchSelectWidth={false}
							allowClear
							className={"dropdown d-xs-block d-md-inline-block"}
						>
							{categoryOptions}
						</Select>
						<span className={"d-xs-none d-md-inline-block"}
							style={{
								paddingRight: 4,
							}}
						>|</span>
						<Select
							placeholder={"Города"}
							showArrow={false}
							style={{ minWidth: 200 }}
							onChange={city =>
								dispatch(
									{
										type: "city",
										payload: city,
									},
								)
							}
							dropdownMatchSelectWidth={false}
							allowClear
							className={"dropdown d-xs-block d-md-inline-block"}
						>
							{cityOptions}
						</Select>
					</div>
				</div>
				<Masonry contentList={news} />
				<div className="d-flex justify-content-center pb-5">
					<Button
						type={"primary"}
						disabled={
							allNewsLoading ||
							loadingFilteredNews ||
							(!category || !city) && !offset ||
							(category || city) && !filteredOffset
						}
						loading={allNewsLoading || loadingFilteredNews}
						onClick={loadMore}
						style={{
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.body,
							backgroundColor: theme.color.text,
						}}
					>
						{showMore}
					</Button>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Все новости"} />
			</div>
		</Pages>
	);
}

AllNewsPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

AllNewsPage.defaultProps = {
	data: {},
	pageContext: {},
};
